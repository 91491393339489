<template>
	<MhRouterView class="NotFoundView view" :isContentReady="isContentReady">
		<div class="view__inner">
			<div class="minHeight100vhMinusMainHeader">

				<br /><strong>NotFoundView.vue</strong><hr /><br />

				<h1>404 for url "{{currentUrl}}"</h1>

			</div>

			<MainFooter
				:data="app.footerData"
			></MainFooter>
		</div>
	</MhRouterView>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v5/MhRouterView.vue'
	import MainFooter from '@/components/MainFooter.vue'

	export default {
		name: 'NotFoundView',
		components: {
			MhRouterView,
			MainFooter,
		},
		props: {},
		data() {
			return {
				isContentReady : false,
			}
		},
		computed: {
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.NotFoundView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
